export const TELECOM_CLIENT_GRAPHQL = process.env.REACT_APP_TELECOM_CLIENT_GRAPHQL ?? ''
export const APP_TIMEZONE = process.env.REACT_APP_TIMEZONE ?? 'US/Mountain'
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT ?? ''

export const environment = process.env.REACT_APP_ENV || ''
const title: { [key: string]: string } = {
  development: 'DEV',
  test: 'TEST',
  conversion: 'CONV',
  release: 'RELEASE',
}
export const showEnv = title[environment] ?? ''
