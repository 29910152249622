import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar'
import React from 'react'

import useAppContext from '../../hooks/useAppContext'

type OmittedProps = 'open' | 'onClose'

export type GlobalSnackbarProps = Omit<SnackbarProps, OmittedProps>

export default function GlobalSnackbar(): JSX.Element {

  const { globalSnackbarProps, setGlobalSnackbarProps } = useAppContext()

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      return
    }

    setGlobalSnackbarProps?.(undefined)
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <Snackbar
      autoHideDuration={5000}
      action={action}
      {...globalSnackbarProps}
      open={!!globalSnackbarProps}
      onClose={handleClose}
    />
  )
}
