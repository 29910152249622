import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'

// Components
import GlobalSnackbar from './components/GlobalSnackbar'
import Router from './components/Router'

// Hooks
import { AppProvider } from './hooks/useAppContext'
import { AuthProvider } from './hooks/useAuth'

// Miscellaneous
import theme from './utils/theme'

export default function App(): JSX.Element {

  return (
    <React.Suspense fallback="">
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppProvider>
                <AuthProvider>
                  <Router />
                  <GlobalSnackbar />
                </AuthProvider>
              </AppProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </React.Suspense>
  )
}

const queryClient = new QueryClient()
