import React from 'react'

import { GlobalSnackbarProps } from '../components/GlobalSnackbar'

interface AppProviderProps {
  children: React.ReactNode
}

interface AppContextInterface {
  globalSnackbarProps?: GlobalSnackbarProps
  setGlobalSnackbarProps?: React.Dispatch<React.SetStateAction<GlobalSnackbarProps | undefined>>
}

const AppContext = React.createContext<AppContextInterface>({} as AppContextInterface)

export function AppProvider({ children }: AppProviderProps): JSX.Element {
  const [globalSnackbarProps, setGlobalSnackbarProps] = React.useState<GlobalSnackbarProps>()

  const appContext: AppContextInterface = {
    globalSnackbarProps,
    setGlobalSnackbarProps,
  }

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
}

export default function useAppContext(): AppContextInterface {
  return React.useContext(AppContext)
}
