import { TELECOM_CLIENT_GRAPHQL } from '../config'
import useAuth from '../hooks/useAuth'

type HeaderType = RequestInit['headers'] & {
    'Content-Type': string
    authorization?: string
}

export const useFetchData = <TData, TVariables>(
  query: string,
  options?: RequestInit['headers'],
): ((variables?: TVariables) => Promise<TData>) => {
  const { authInformation } = useAuth()

  const headers: HeaderType = {
    'Content-Type': 'application/json',
    ...options,
  }

  if(authInformation?.token) {
    headers['authorization'] = `bearer ${authInformation.token}`
  }

  return async (variables?: TVariables) => {
    const res = await fetch(TELECOM_CLIENT_GRAPHQL, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        query,
        variables,
      }),
    })

    const json = await res.json()

    if (json.errors) {
      const { message } = json.errors[0] || {}
      throw new Error(message || 'Error…')
    }

    return json.data
  }
}
