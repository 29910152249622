import { Account, SessionContext } from '../generated/telecomGraphqlService'

export interface ValidAccount extends Account {
  yearOpened?: number | null
}

export enum BusinessAssetsFilingStep {
  AccountInformation = 'Account Information',
  PropertyAssetList = 'Property Asset List',
  LeasedAssetList = 'Leased Asset List',
  SummaryAndSignature = 'Summary & Signature',
  ThankYou = 'Thank You',
}

export interface User {
  accountNumber: string
  businessName: string
  taxYear: number
  completedSteps: BusinessAssetsFilingStep[]
  delinquentTaxYears: SessionContext['delinquentTaxYears']
}

export interface CountySettings {
  name: string
  supportUrl: string
  paymentUrl: string | null
}

interface SummaryTotalCounter {
  units?: number
  cost: number
  value: number
}

export interface Summary {
  assetsDeclared: SummaryTotalCounter
  netTotal: SummaryTotalCounter
  propertyAssetListURL: string
  leasedAssetListURL?: string
}

export interface SummaryAndSignaturePayload {
  preparedAndSubmittedBy: string
  email: string
  phone: string
  pin: string
}

export interface OATHType {
  taxYear: number
  exemptionLimit: number
  filingDueDate: string
  interestRate: number
  interestStartDate: string
  countyAppealFormURL: string | null
  userInfo: null
}

export interface AuthInformation {
  token: string
  userId: number
  taxYear: number
  exp: number
}
