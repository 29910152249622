import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { Outlet } from 'react-router-dom'

import Header from './Header'

export default function Layout(): JSX.Element {
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column">
      <Header />
      <Container component="main">
        <Box className={classes.container}>
          <Outlet />
        </Box>
      </Container>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
}))
