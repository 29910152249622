import { blue, grey } from '@mui/material/colors/'
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles'
// TODO: Replace this import when Material-UI v5 comes out
// https://github.com/mui-org/material-ui/issues/13394

export default createMuiTheme({
  palette: { primary: blue },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: grey[100],
          height: '100vh',
        },
        '#root': {
          height: '100%',
        },
      },
    },
  },
})
