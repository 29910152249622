import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import useAuth from '../../hooks/useAuth'
import { nextStep } from '../../utils/nextStep'
import paths from '../../utils/paths'

export default function StepRedirector(): JSX.Element {
  const { authInformation, user, signOut } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    if (!authInformation || !user) {
      signOut?.()
      navigate(paths.login)
    } else {
      navigate(nextStep(user.completedSteps))
    }
  }, [authInformation, user, navigate, signOut])

  return <Loader loading={true} />
}
