const paths = {
  telecomAssetsFilling: '/telecom-assets-filing',
  accountInformation: '/telecom-assets-filing/account-information',
  propertyAssetList: '/telecom-assets-filing/property-asset-list',
  leasedAssetList: '/telecom-assets-filing/leased-asset-list',
  summaryAndSignature: '/telecom-assets-filing/summary-and-signature',
  index: '/',
  forgotPin: '/forgot-pin',
  login: '/login',
  thankYou: '/thank-you',
} as const

export type Path = typeof paths[keyof typeof paths]

export default paths
