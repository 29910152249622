import jwtDecode, { JwtPayload } from 'jwt-decode'
import React from 'react'
import { SessionContext as GraphQlSessionContext } from '../generated/telecomGraphqlService'
import { AuthInformation, User } from '../models/api'
import { mapToStep } from '../utils/stepMap'
import useLocalStorage from './useLocalStorage'

interface JwtCustomPayload extends JwtPayload {
  userId: number
  taxYear: number
}

interface AuthProviderProps {
  children: React.ReactNode
}

interface AuthContext {
  user: User
  authInformation: AuthInformation
  setUser?: React.Dispatch<React.SetStateAction<boolean>>
  showDelinquentNoticeBanner?: boolean
  setShowDelinquentNoticeBanner?: React.Dispatch<boolean>
  storeToken?: (token: string) => Promise<void>
  storeSessionContext?: (sessionContext: GraphQlSessionContext) => Promise<void>
  signOut?: () => void
}

const AuthContext = React.createContext<AuthContext>({} as AuthContext)

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [user, setUser] = useLocalStorage<User | null>('user', null)
  const [authInformation, setAuthInformation] = useLocalStorage<AuthInformation | null>('authInformation', null)
  const [showDelinquentNoticeBanner, setShowDelinquentNoticeBanner] = useLocalStorage<boolean>('showDelinquentNoticeBanner', true)


  const storeToken = async (token: string): Promise<void> => {
    const payload = jwtDecode<JwtCustomPayload>(token)
    const newAuthInformation: AuthInformation = {
      token: token,
      taxYear: payload.taxYear,
      userId: payload.userId,
      exp: payload.exp ?? 0,
    }

    setAuthInformation(newAuthInformation)
  }

  const storeSessionContext = async (sessionContext: GraphQlSessionContext): Promise<void> => {
    const newUserContext: User = {
      accountNumber: sessionContext.accountNumber,
      businessName: sessionContext.businessName,
      completedSteps: sessionContext.stepsCompleted.map(mapToStep),
      taxYear: sessionContext.taxYear,
      delinquentTaxYears: sessionContext.delinquentTaxYears,
    }

    setUser(newUserContext)
  }

  const signOut = (): void => {
    setUser(null)
    setAuthInformation(null)
    setShowDelinquentNoticeBanner(true)
  }

  const authContext = {
    user: user as User,
    authInformation: authInformation as AuthInformation,
    storeToken,
    storeSessionContext,
    showDelinquentNoticeBanner,
    setShowDelinquentNoticeBanner,
    signOut,
  }

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
}

export default function useAuth(): AuthContext {
  return React.useContext(AuthContext)
}
