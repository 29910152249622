import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

// Miscellaneous
import LoginRedirector from '../../pages/Login/LoginRedirector'
import StepRedirector from '../../pages/StepRedirector'
import paths from '../../utils/paths'

// Components
import Layout from '../Layout'
import Loader from '../Loader'
import ProtectedRoute from '../ProtectedRoute'

// Pages
const AccountInformationPage = lazy(() => import('../../pages/AccountInformationPage'))
const ForgotPin = lazy(() => import('../../pages/ForgotPin'))
const LeasedAssetListPage = lazy(() => import('../../pages/LeasedAssetListPage'))
const Login = lazy(() => import('../../pages/Login'))
const PageNotFound = lazy(() => import('../../pages/PageNotFound'))
const PropertyAssetListPage = lazy(() => import('../../pages/PropertyAssetListPage'))
const SummaryAndSignaturePage = lazy(() => import('../../pages/SummaryAndSignaturePage'))
const ThankYouPage = lazy(() => import('../../pages/ThankYouPage'))

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={paths.index} element={<StepRedirector />} />
        <Route element={<LoginRedirector />}>
          <Route
            path={paths.login}
            element={
              <Suspense fallback={<Loader loading />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path={paths.forgotPin}
            element={
              <Suspense fallback={<Loader loading />}>
                <ForgotPin />
              </Suspense>
            }
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path={paths.telecomAssetsFilling}>
            <Route
              path={paths.accountInformation}
              element={
                <Suspense fallback={<Loader loading />}>
                  <AccountInformationPage />
                </Suspense>
              }
            />
            <Route
              path={paths.propertyAssetList}
              element={
                <Suspense fallback={<Loader loading />}>
                  <PropertyAssetListPage />
                </Suspense>
              }
            />
            <Route
              path={paths.leasedAssetList}
              element={
                <Suspense fallback={<Loader loading />}>
                  <LeasedAssetListPage />
                </Suspense>
              }
            />
            <Route
              path={paths.summaryAndSignature}
              element={
                <Suspense fallback={<Loader loading />}>
                  <SummaryAndSignaturePage />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={paths.thankYou}
            element={
              <Suspense fallback={<Loader loading />}>
                <ThankYouPage />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Suspense fallback={<Loader loading />}>
              <PageNotFound />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  )
}
