import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ServerDownSVG } from '../../svgs/serverDown.svg'

interface Props {
  tryAgain?: () => void
}

export default function LoadingFetchError(props: Props): JSX.Element {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Stack spacing={6} p={6} alignItems="center">
          <Box>
            <Typography variant="h4" component="h3">{t('oopsSomethingWentWrong')}!</Typography>
            <Typography>{t('weCoundntFetchYourDataCorrectly')}!</Typography>
          </Box>
          <Box>
            <ServerDownSVG width="100%" height="auto"/>
          </Box>
          <Button onClick={props.tryAgain}>Try Again</Button>
        </Stack>
      </Paper>
    </React.Fragment>
  )
}
