import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { SessionContext, SessionContextQuery, useSessionContextQuery } from '../generated/telecomGraphqlService'
import { BusinessAssetsFilingStep } from '../models/api'
import { nextStep } from '../utils/nextStep'
import paths from '../utils/paths'
import { goToThankYouPage } from '../utils/routeActions'
import { mapToStep } from '../utils/stepMap'
import useAuth from './useAuth'

interface UseForcedNavigationReturnType {
  isSessionContextLoading: boolean
  sessionContextHasErrors: boolean
  refetchSessionContext: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined) => Promise<QueryObserverResult<SessionContextQuery, unknown>>
}

export default function useForcedNavigation(): UseForcedNavigationReturnType {
  const location = useLocation()
  const step = getStep(location.pathname)
  const [isSessionContextLoading, setIsSessionContextLoading] = React.useState(true)

  const navigate = useNavigate()
  const { storeSessionContext } = useAuth()

  const {
    refetch: refetchSessionContext,
    isError: sessionContextHasErrors,
  } = useSessionContextQuery(
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
      networkMode: 'always',
      cacheTime: 0,
      onError: () => {
        setIsSessionContextLoading(false)
      },
      onSuccess: ({ getSessionContext }) => {
        if (step === null) {
          setIsSessionContextLoading(false)
          return
        }

        storeSessionContext?.(getSessionContext as SessionContext)

        if (getSessionContext?.stepsCompleted) {
          const completedSteps = getSessionContext?.stepsCompleted.map(mapToStep)

          if (hasTheWorkFlowBeenCompleted(completedSteps) && step !== BusinessAssetsFilingStep.ThankYou) {
            return goToThankYouPage(navigate)
          }

          if (hasPermissions(step, completedSteps)) {
            setIsSessionContextLoading(false)
            return
          }

          navigate(nextStep(completedSteps))
        }
      },
    },
  )

  React.useEffect(() => {
    if (window.location.href) {
      refetchSessionContext()
      setIsSessionContextLoading(true)
    }

    // eslint-disable-next-line
  }, [window.location.href])

  return { isSessionContextLoading, sessionContextHasErrors, refetchSessionContext }
}

export function hasTheWorkFlowBeenCompleted(completedSteps: BusinessAssetsFilingStep[]): boolean {
  const hasBeenCompleted = [
    BusinessAssetsFilingStep.AccountInformation,
    BusinessAssetsFilingStep.PropertyAssetList,
    BusinessAssetsFilingStep.LeasedAssetList,
    BusinessAssetsFilingStep.SummaryAndSignature,
  ].every((step) => completedSteps.includes(step))

  return hasBeenCompleted
}

function hasPermissions(
  step: BusinessAssetsFilingStep,
  completedSteps: BusinessAssetsFilingStep[]
): boolean {

  const permissionsByStep = {
    [BusinessAssetsFilingStep.PropertyAssetList]: [
      BusinessAssetsFilingStep.AccountInformation,
    ],
    [BusinessAssetsFilingStep.LeasedAssetList]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.PropertyAssetList,
    ],
    [BusinessAssetsFilingStep.SummaryAndSignature]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.PropertyAssetList,
      BusinessAssetsFilingStep.LeasedAssetList,
    ],
    [BusinessAssetsFilingStep.ThankYou]: [
      BusinessAssetsFilingStep.AccountInformation,
      BusinessAssetsFilingStep.PropertyAssetList,
      BusinessAssetsFilingStep.LeasedAssetList,
      BusinessAssetsFilingStep.SummaryAndSignature,
    ],
  }

  if (step === BusinessAssetsFilingStep.AccountInformation) {
    return true
  }

  const permission = permissionsByStep[step]

  return checkIfStepHasPermissions(permission, completedSteps)
}

function checkIfStepHasPermissions(
  permissions: BusinessAssetsFilingStep[],
  completedSteps: BusinessAssetsFilingStep[],
): boolean {
  return permissions.every((permission) => completedSteps.includes(permission))
}

function getStep(pathname: string): BusinessAssetsFilingStep | null {
  if (pathname.includes(paths.accountInformation)) {
    return BusinessAssetsFilingStep.AccountInformation
  }

  if (pathname.includes(paths.propertyAssetList)) {
    return BusinessAssetsFilingStep.PropertyAssetList
  }

  if (pathname.includes(paths.leasedAssetList)) {
    return BusinessAssetsFilingStep.LeasedAssetList
  }

  if (pathname.includes(paths.summaryAndSignature)) {
    return BusinessAssetsFilingStep.SummaryAndSignature
  }

  if (pathname.includes(paths.thankYou)) {
    return BusinessAssetsFilingStep.ThankYou
  }

  return null
}
