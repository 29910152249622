import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import useForcedNavigation from '../../hooks/useForcedNavigation'
import paths from '../../utils/paths'
import Loader from '../Loader'
import LoadingFetchError from '../LoadingFetchError'

export default function ProtectedRoute(): JSX.Element {
  const { authInformation, user, signOut } = useAuth()
  const navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(false)


  useEffect(() => {
    const isExpired = (): boolean => {
      return !authInformation || Date.now() > authInformation.exp * 1000
    }

    if (authInformation && user && !isExpired()) {
      setLoggedIn(true)
    } else {
      signOut?.()
      navigate(paths.login)
    }
  }, [authInformation, user, signOut, navigate])

  const { isSessionContextLoading, sessionContextHasErrors, refetchSessionContext } = useForcedNavigation()

  if (sessionContextHasErrors) {
    <LoadingFetchError tryAgain={refetchSessionContext} />
  }

  if (isSessionContextLoading) {
    return <Loader loading={true} />
  }

  if (loggedIn) {
    return <Outlet />
  }

  return <Loader loading={true} />
}
