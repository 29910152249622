import { hasTheWorkFlowBeenCompleted } from '../hooks/useForcedNavigation'
import { BusinessAssetsFilingStep } from '../models/api'
import paths from './paths'

const steps = {
  [BusinessAssetsFilingStep.AccountInformation]: paths.accountInformation,
  [BusinessAssetsFilingStep.PropertyAssetList]: paths.propertyAssetList,
  [BusinessAssetsFilingStep.LeasedAssetList]: paths.leasedAssetList,
  [BusinessAssetsFilingStep.SummaryAndSignature]: paths.summaryAndSignature,
}

export function nextStep(completedSteps: BusinessAssetsFilingStep[]): string {
  if (hasTheWorkFlowBeenCompleted(completedSteps)) {
    return paths.thankYou
  }

  if (!completedSteps.includes(BusinessAssetsFilingStep.AccountInformation)) {
    return paths.accountInformation
  }

  if (!completedSteps.includes(BusinessAssetsFilingStep.PropertyAssetList)) {
    return paths.propertyAssetList
  }

  if (!completedSteps.includes(BusinessAssetsFilingStep.LeasedAssetList)) {
    return paths.leasedAssetList
  }

  if (!completedSteps.includes(BusinessAssetsFilingStep.SummaryAndSignature)) {
    return paths.summaryAndSignature
  }

  return steps[BusinessAssetsFilingStep.AccountInformation]
}
