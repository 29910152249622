import { NavigateFunction, NavigateOptions } from 'react-router-dom'
import paths from './paths'

export function goToAccountInformation(navigate: NavigateFunction): void {
  navigate(paths.accountInformation)
}

export function goToPropertyAssetList(navigate: NavigateFunction): void {
  navigate(paths.propertyAssetList)
}

export function goToLeasedAssetList(navigate: NavigateFunction): void {
  navigate(paths.leasedAssetList)
}

export function goToSummaryAndSignature(navigate: NavigateFunction, options?: NavigateOptions): void {
  navigate(paths.summaryAndSignature, options)
}

export function goToThankYouPage(navigate: NavigateFunction): void {
  navigate(paths.thankYou)
}

export type GoToPage =
  | typeof goToAccountInformation
  | typeof goToPropertyAssetList
  | typeof goToLeasedAssetList
  | typeof goToSummaryAndSignature
  | typeof goToThankYouPage
