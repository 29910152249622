import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Divider from '@mui/material/Divider'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth'
import { User } from '../../models/api'
import paths from '../../utils/paths'

interface Props extends Omit<DrawerProps, 'onClose' | 'classes'> {
  user: User
  onDrawerClose?(): void
}

export default function NavigationDrawer(props: Props): JSX.Element {
  const { user, onDrawerClose, ...drawerProps } = props

  const { signOut } = useAuth()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const classes = useStyles()

  const signOutAndRedirect = (): void => {
    props.onDrawerClose?.()
    signOut?.()
    navigate(paths.login)
  }

  return (
    <Drawer {...drawerProps} onClose={onDrawerClose} classes={classes}>
      <List>
        <ListItem>
          <ListItemText primary={t('accountNumberLabel', user)} secondary={t('taxYearLabel', user)} />
          <IconButton onClick={onDrawerClose} aria-label={t('hideNavigationMenu')} size="large">
            <ChevronLeftIcon />
          </IconButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={signOutAndRedirect}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={t('signOut')} />
        </ListItem>
      </List>
    </Drawer>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: { width: theme.spacing(32) },
}))
