import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetchData } from '../graphql/customFetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** An organization registered in the County's system */
export type Account = {
  businessDescription?: Maybe<Scalars['String']>;
  employerIdentificationNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  otherContacts: Array<NonPrimaryContact>;
  primaryContact: PrimaryContact;
  registeredEntityName?: Maybe<Scalars['String']>;
  yearOpened?: Maybe<Scalars['Int']>;
};

export type AccountInput = {
  businessDescription?: InputMaybe<Scalars['String']>;
  employerIdentificationNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  otherContacts: Array<NonPrimaryContactInput>;
  primaryContact: PrimaryContactInput;
  registeredEntityName?: InputMaybe<Scalars['String']>;
  yearOpened?: InputMaybe<Scalars['Int']>;
};

/** A location identified by street, city, and state */
export type Address = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  inCareOf?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  zipCode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine1: Scalars['String'];
  addressLine2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  inCareOf?: InputMaybe<Scalars['String']>;
  state: State;
  zipCode: Scalars['String'];
};

export type AddressOtherContactInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  inCareOf?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State>;
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Identifies the user authentication */
export type AuthenticationType = {
  token?: Maybe<Scalars['String']>;
};

/** Point of Contact that can recieve messages from the County */
export type Contact = {
  cellphone?: Maybe<Scalars['String']>;
  contactType: ContactType;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: Maybe<Address>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
};

export type ContactInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  preparedAndSubmittedBy: Scalars['String'];
};

export type ContactType = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ContactTypeInput = {
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ExampleFile = {
  error_FileName?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type FileExtension = {
  csv?: Maybe<FileInfo>;
  pdf?: Maybe<FileInfo>;
};

/** Represents file information */
export type FileInfo = {
  fileUrl?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
};

export type GetLeaseAssetList = {
  accountNumber: Scalars['ID'];
  countyContactsURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  templateURL: Scalars['String'];
  validationReport?: Maybe<Scalars['String']>;
  validationReportName?: Maybe<Scalars['String']>;
};

export type GetPropertyAssetList = {
  accountNumber: Scalars['ID'];
  countyContactsURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  templateURL: Scalars['String'];
  validationReport?: Maybe<Scalars['String']>;
  validationReportName?: Maybe<Scalars['String']>;
};

/** Identify the jurisdiction in which the Telecom Account falls into */
export type JurisdictionType = {
  /** Human-readable label used to display in the application */
  countyName: Scalars['String'];
  id: Scalars['ID'];
};

export type Mutation = {
  dismissLeaseAssetList?: Maybe<Scalars['Boolean']>;
  /** Generates a SAS URL that allows you to upload a file in azure with a random csv name */
  generatePresignedUploadCsvFile?: Maybe<PresignedUploadCsvFile>;
  login?: Maybe<AuthenticationType>;
  pinReset?: Maybe<Scalars['Boolean']>;
  removePropertyAssetList?: Maybe<Scalars['Boolean']>;
  saveAccount?: Maybe<Account>;
  saveLeaseAssetList?: Maybe<SavedLeaseAssetList>;
  savePropertyAssetList?: Maybe<SavedPropertyAssetList>;
  saveSummary?: Maybe<Scalars['Boolean']>;
  skipLeaseAssetList?: Maybe<Scalars['Boolean']>;
};


export type MutationDismissLeaseAssetListArgs = {
  accountNumber: Scalars['ID'];
};


export type MutationGeneratePresignedUploadCsvFileArgs = {
  isLeased: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  accountNumber: Scalars['String'];
  pin: Scalars['String'];
};


export type MutationPinResetArgs = {
  pinResetRequestInput: PinResetRequestInput;
};


export type MutationSaveAccountArgs = {
  account: AccountInput;
};


export type MutationSaveLeaseAssetListArgs = {
  accountNumber: Scalars['ID'];
  fileName: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationSavePropertyAssetListArgs = {
  accountNumber: Scalars['ID'];
  fileName: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationSaveSummaryArgs = {
  summaryInput: SummaryInput;
};


export type MutationSkipLeaseAssetListArgs = {
  accountNumber: Scalars['ID'];
};

/** Other possible Point of Contact that the County can alternatively reach */
export type NonPrimaryContact = {
  cellphone?: Maybe<Scalars['String']>;
  contactType: ContactType;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress?: Maybe<Address>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
};

export type NonPrimaryContactInput = {
  cellphone?: InputMaybe<Scalars['String']>;
  contactType: ContactTypeInput;
  email: Scalars['String'];
  id: Scalars['ID'];
  mailingAddress?: InputMaybe<AddressOtherContactInput>;
  name: Scalars['String'];
  phone: Scalars['String'];
  phoneExtension?: InputMaybe<Scalars['String']>;
};

export type OathStatement = {
  exemptionLimit?: Maybe<Scalars['Float']>;
  filingDueDate?: Maybe<Scalars['String']>;
  interestRate?: Maybe<Scalars['Float']>;
  penaltyAmount?: Maybe<Scalars['String']>;
  penaltyPercent?: Maybe<Scalars['String']>;
  taxYear?: Maybe<Scalars['Int']>;
};

export type PinResetContactInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type PinResetRequestInput = {
  contact: PinResetContactInput;
  telecomName: Scalars['String'];
  telecomUsername: Scalars['String'];
};

export type PresignedUploadCsvFile = {
  fileName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Main Point of Contact that will recieve mail from the County */
export type PrimaryContact = Contact & {
  cellphone?: Maybe<Scalars['String']>;
  contactType: ContactType;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mailingAddress: Address;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  phoneExtension?: Maybe<Scalars['String']>;
};

export type PrimaryContactInput = {
  cellphone?: InputMaybe<Scalars['String']>;
  contactType: ContactTypeInput;
  email: Scalars['String'];
  id: Scalars['ID'];
  mailingAddress: AddressInput;
  name: Scalars['String'];
  phone: Scalars['String'];
  phoneExtension?: InputMaybe<Scalars['String']>;
};

export type Query = {
  contactTypes: Array<ContactType>;
  getAccount?: Maybe<Account>;
  getExampleFile?: Maybe<ExampleFile>;
  getLeaseAssetList?: Maybe<GetLeaseAssetList>;
  getOathStatement: OathStatement;
  getPropertyAssetList?: Maybe<GetPropertyAssetList>;
  getSessionContext?: Maybe<SessionContext>;
  getSummary?: Maybe<Summary>;
  getThankYou?: Maybe<ThankYouType>;
  jurisdictionTypes: Array<JurisdictionType>;
};


export type QueryGetExampleFileArgs = {
  isLeased?: InputMaybe<Scalars['Boolean']>;
};


export type QueryGetLeaseAssetListArgs = {
  accountNumber: Scalars['ID'];
};


export type QueryGetPropertyAssetListArgs = {
  accountNumber: Scalars['ID'];
};

export type SavedAzureFunction = {
  accountNbr: Scalars['String'];
  fileName: Scalars['String'];
  isLeased: Scalars['Int'];
  taxYear: Scalars['Int'];
  userId: Scalars['ID'];
};

export type SavedLeaseAssetList = {
  accountNumber: Scalars['ID'];
  fileName: Scalars['String'];
  validationReport?: Maybe<Scalars['String']>;
};

export type SavedPropertyAssetList = {
  accountNumber: Scalars['ID'];
  fileName: Scalars['String'];
  validationReport?: Maybe<Scalars['String']>;
};

export type SavedPropertyAssetListAzureFunction = {
  accountNbr: Scalars['String'];
  fileName: Scalars['String'];
  taxYear: Scalars['Int'];
  userId: Scalars['ID'];
};

/** Session context */
export type SessionContext = {
  accountNumber: Scalars['String'];
  businessName: Scalars['String'];
  delinquentTaxYears: Array<Scalars['String']>;
  stepsCompleted: Array<Scalars['String']>;
  taxYear: Scalars['Int'];
};

/**
 * According to Postal Addressing Standards - Appendix B
 * https://pe.usps.com/text/pub28/28apb.htm
 */
export enum State {
  /** Armed Forces Americas (except Canada) */
  Aa = 'AA',
  /** Armed Forces Europe, the Middle East, and Canada */
  Ae = 'AE',
  /** Alaska */
  Ak = 'AK',
  /** Alabama */
  Al = 'AL',
  /** Armed Forces Pacific */
  Ap = 'AP',
  /** Arkansas */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Arizona */
  Az = 'AZ',
  /** California */
  Ca = 'CA',
  /** Colorado */
  Co = 'CO',
  /** Connecticut */
  Ct = 'CT',
  /** District of Columbia */
  Dc = 'DC',
  /** Delaware */
  De = 'DE',
  /** Florida */
  Fl = 'FL',
  /** Federated Stated of Micronesia */
  Fm = 'FM',
  /** Georgia */
  Ga = 'GA',
  /** Guam */
  Gu = 'GU',
  /** Hawaii */
  Hi = 'HI',
  /** Iowa */
  Ia = 'IA',
  /** Idaho */
  Id = 'ID',
  /** Illinois */
  Il = 'IL',
  /** Indiana */
  In = 'IN',
  /** Kansas */
  Ks = 'KS',
  /** Kentucky */
  Ky = 'KY',
  /** Louisiana */
  La = 'LA',
  /** Massachusetts */
  Ma = 'MA',
  /** Maryland */
  Md = 'MD',
  /** Maine */
  Me = 'ME',
  /** Marshall Islands */
  Mh = 'MH',
  /** Michigan */
  Mi = 'MI',
  /** Minnesota */
  Mn = 'MN',
  /** Missouri */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Mississippi */
  Ms = 'MS',
  /** Montana */
  Mt = 'MT',
  /** North Carolina */
  Nc = 'NC',
  /** North Dakota */
  Nd = 'ND',
  /** Nebraska */
  Ne = 'NE',
  /** New Hampshire */
  Nh = 'NH',
  /** New Jersey */
  Nj = 'NJ',
  /** New Mexico */
  Nm = 'NM',
  /** Nevada */
  Nv = 'NV',
  /** New York */
  Ny = 'NY',
  /** Ohio */
  Oh = 'OH',
  /** Oklahoma */
  Ok = 'OK',
  /** Oregon */
  Or = 'OR',
  /** Pennsylvania */
  Pa = 'PA',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palau */
  Pw = 'PW',
  /** Rhode Island */
  Ri = 'RI',
  /** South Carolina */
  Sc = 'SC',
  /** South Dakota */
  Sd = 'SD',
  /** Tennessee */
  Tn = 'TN',
  /** Texas */
  Tx = 'TX',
  /** Utah */
  Ut = 'UT',
  /** Virginia */
  Va = 'VA',
  /** Virgin Islands */
  Vi = 'VI',
  /** Vermont */
  Vt = 'VT',
  /** Washington */
  Wa = 'WA',
  /** Wisconsin */
  Wi = 'WI',
  /** West Virginia */
  Wv = 'WV',
  /** Wyoming */
  Wy = 'WY'
}

/** Summary - Step 4 */
export type Summary = {
  accountNumber: Scalars['String'];
  assetDeclared?: Maybe<SummaryTableRow>;
  contacts: Array<SummaryContactRow>;
  countyContactsURL?: Maybe<Scalars['String']>;
  leasedAssetListURL?: Maybe<FileExtension>;
  netTotal?: Maybe<SummaryTableRow>;
  propertyAssetListURL?: Maybe<FileExtension>;
};

export type SummaryContactRow = {
  contactType: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type SummaryInput = {
  accountNumber: Scalars['ID'];
  contact: ContactInput;
  pin: Scalars['String'];
};

export type SummaryTableRow = {
  cost: Scalars['Float'];
  units?: Maybe<Scalars['Int']>;
  value: Scalars['Float'];
};

/** Thank you page information to show */
export type ThankYouType = {
  accountName: Scalars['String'];
  accountNumber: Scalars['String'];
  countyContactsURL?: Maybe<Scalars['String']>;
  filingDate: Scalars['String'];
  filingDeadline: Scalars['String'];
  filingTime: Scalars['String'];
  interestRate: Scalars['Float'];
  leaseFile?: Maybe<FileExtension>;
  nonLeaseFile?: Maybe<FileExtension>;
  penaltyPercent: Scalars['String'];
  taxYear: Scalars['Int'];
};

export type GetAccountInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountInformationQuery = { getAccount?: { id: string, yearOpened?: number | null, businessDescription?: string | null, registeredEntityName?: string | null, employerIdentificationNumber?: string | null, primaryContact: { id: string, name: string, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, contactType: { id: string, label: string }, mailingAddress: { inCareOf?: string | null, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: State | null, zipCode?: string | null } }, otherContacts: Array<{ id: string, name: string, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, contactType: { id: string, label: string }, mailingAddress?: { addressLine1?: string | null, addressLine2?: string | null, inCareOf?: string | null, city?: string | null, state?: State | null, zipCode?: string | null } | null }> } | null, contactTypes: Array<{ id: string, label: string }> };

export type SaveAccountMutationVariables = Exact<{
  account: AccountInput;
}>;


export type SaveAccountMutation = { saveAccount?: { id: string, yearOpened?: number | null, businessDescription?: string | null, registeredEntityName?: string | null, employerIdentificationNumber?: string | null, primaryContact: { id: string, name: string, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, contactType: { id: string, label: string }, mailingAddress: { inCareOf?: string | null, addressLine1?: string | null, addressLine2?: string | null, city?: string | null, state?: State | null, zipCode?: string | null } }, otherContacts: Array<{ id: string, name: string, email?: string | null, phone?: string | null, phoneExtension?: string | null, cellphone?: string | null, contactType: { id: string, label: string }, mailingAddress?: { addressLine1?: string | null, addressLine2?: string | null, inCareOf?: string | null, city?: string | null, state?: State | null, zipCode?: string | null } | null }> } | null };

export type GeneratePresignedUploadCsvFileMutationVariables = Exact<{
  isLeased: Scalars['Boolean'];
}>;


export type GeneratePresignedUploadCsvFileMutation = { generatePresignedUploadCsvFile?: { url?: string | null, fileName?: string | null } | null };

export type GetPropertyAssetListQueryVariables = Exact<{
  accountNumber: Scalars['ID'];
}>;


export type GetPropertyAssetListQuery = { getPropertyAssetList?: { accountNumber: string, fileName?: string | null, fileUrl?: string | null, validationReportName?: string | null, validationReport?: string | null, templateURL: string, countyContactsURL?: string | null } | null };

export type RemovePropertyAssetListMutationVariables = Exact<{ [key: string]: never; }>;


export type RemovePropertyAssetListMutation = { removePropertyAssetList?: boolean | null };

export type SavePropertyAssetListMutationVariables = Exact<{
  accountNumber: Scalars['ID'];
  fileName: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type SavePropertyAssetListMutation = { savePropertyAssetList?: { accountNumber: string, fileName: string, validationReport?: string | null } | null };

export type LoginMutationVariables = Exact<{
  accountNumber: Scalars['String'];
  pin: Scalars['String'];
}>;


export type LoginMutation = { login?: { token?: string | null } | null };

export type PinResetMutationVariables = Exact<{
  pinResetRequestInput: PinResetRequestInput;
}>;


export type PinResetMutation = { pinReset?: boolean | null };

export type GetSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSummaryQuery = { getSummary?: { accountNumber: string, countyContactsURL?: string | null, assetDeclared?: { units?: number | null, cost: number, value: number } | null, netTotal?: { cost: number, value: number } | null, leasedAssetListURL?: { pdf?: { filename?: string | null, fileUrl?: string | null } | null, csv?: { filename?: string | null, fileUrl?: string | null } | null } | null, propertyAssetListURL?: { pdf?: { filename?: string | null, fileUrl?: string | null } | null, csv?: { filename?: string | null, fileUrl?: string | null } | null } | null, contacts: Array<{ id: string, name: string, email: string, phone: string, contactType: string }> } | null };

export type SaveSummaryMutationVariables = Exact<{
  summaryInput: SummaryInput;
}>;


export type SaveSummaryMutation = { saveSummary?: boolean | null };

export type GetOathStatementQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOathStatementQuery = { getOathStatement: { taxYear?: number | null, exemptionLimit?: number | null, filingDueDate?: string | null, interestRate?: number | null, penaltyAmount?: string | null, penaltyPercent?: string | null } };

export type SessionContextQueryVariables = Exact<{ [key: string]: never; }>;


export type SessionContextQuery = { getSessionContext?: { accountNumber: string, businessName: string, taxYear: number, stepsCompleted: Array<string>, delinquentTaxYears: Array<string> } | null };

export type GetLeaseAssetListQueryVariables = Exact<{
  accountNumber: Scalars['ID'];
}>;


export type GetLeaseAssetListQuery = { getLeaseAssetList?: { accountNumber: string, fileName?: string | null, fileUrl?: string | null, validationReportName?: string | null, validationReport?: string | null, templateURL: string, countyContactsURL?: string | null } | null };

export type SaveLeaseAssetListMutationVariables = Exact<{
  accountNumber: Scalars['ID'];
  fileName: Scalars['String'];
  userId: Scalars['ID'];
}>;


export type SaveLeaseAssetListMutation = { saveLeaseAssetList?: { accountNumber: string, fileName: string, validationReport?: string | null } | null };

export type SkipLeaseAssetListMutationVariables = Exact<{
  accountNumber: Scalars['ID'];
}>;


export type SkipLeaseAssetListMutation = { skipLeaseAssetList?: boolean | null };

export type DismissLeaseAssetListMutationVariables = Exact<{
  accountNumber: Scalars['ID'];
}>;


export type DismissLeaseAssetListMutation = { dismissLeaseAssetList?: boolean | null };

export type ThankYouQueryVariables = Exact<{ [key: string]: never; }>;


export type ThankYouQuery = { getThankYou?: { taxYear: number, accountName: string, accountNumber: string, filingDeadline: string, interestRate: number, filingDate: string, filingTime: string, countyContactsURL?: string | null, penaltyPercent: string, leaseFile?: { pdf?: { filename?: string | null, fileUrl?: string | null } | null, csv?: { filename?: string | null, fileUrl?: string | null } | null } | null, nonLeaseFile?: { pdf?: { filename?: string | null, fileUrl?: string | null } | null, csv?: { filename?: string | null, fileUrl?: string | null } | null } | null } | null };

export type GetExampleFileQueryVariables = Exact<{
  isLeased?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetExampleFileQuery = { getExampleFile?: { fileName?: string | null } | null };


export const GetAccountInformationDocument = `
    query GetAccountInformation {
  getAccount {
    id
    yearOpened
    businessDescription
    registeredEntityName
    employerIdentificationNumber
    primaryContact {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      mailingAddress {
        inCareOf
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
    otherContacts {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      mailingAddress {
        addressLine1
        addressLine2
        inCareOf
        city
        state
        zipCode
      }
    }
  }
  contactTypes {
    id
    label
  }
}
    `;
export const useGetAccountInformationQuery = <
      TData = GetAccountInformationQuery,
      TError = unknown
    >(
      variables?: GetAccountInformationQueryVariables,
      options?: UseQueryOptions<GetAccountInformationQuery, TError, TData>
    ) =>
    useQuery<GetAccountInformationQuery, TError, TData>(
      variables === undefined ? ['GetAccountInformation'] : ['GetAccountInformation', variables],
      useFetchData<GetAccountInformationQuery, GetAccountInformationQueryVariables>(GetAccountInformationDocument).bind(null, variables),
      options
    );
export const SaveAccountDocument = `
    mutation SaveAccount($account: AccountInput!) {
  saveAccount(account: $account) {
    id
    yearOpened
    businessDescription
    registeredEntityName
    employerIdentificationNumber
    primaryContact {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      mailingAddress {
        inCareOf
        addressLine1
        addressLine2
        city
        state
        zipCode
      }
    }
    otherContacts {
      id
      contactType {
        id
        label
      }
      name
      email
      phone
      phoneExtension
      cellphone
      mailingAddress {
        addressLine1
        addressLine2
        inCareOf
        city
        state
        zipCode
      }
    }
  }
}
    `;
export const useSaveAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveAccountMutation, TError, SaveAccountMutationVariables, TContext>) =>
    useMutation<SaveAccountMutation, TError, SaveAccountMutationVariables, TContext>(
      ['SaveAccount'],
      useFetchData<SaveAccountMutation, SaveAccountMutationVariables>(SaveAccountDocument),
      options
    );
export const GeneratePresignedUploadCsvFileDocument = `
    mutation GeneratePresignedUploadCsvFile($isLeased: Boolean!) {
  generatePresignedUploadCsvFile(isLeased: $isLeased) {
    url
    fileName
  }
}
    `;
export const useGeneratePresignedUploadCsvFileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GeneratePresignedUploadCsvFileMutation, TError, GeneratePresignedUploadCsvFileMutationVariables, TContext>) =>
    useMutation<GeneratePresignedUploadCsvFileMutation, TError, GeneratePresignedUploadCsvFileMutationVariables, TContext>(
      ['GeneratePresignedUploadCsvFile'],
      useFetchData<GeneratePresignedUploadCsvFileMutation, GeneratePresignedUploadCsvFileMutationVariables>(GeneratePresignedUploadCsvFileDocument),
      options
    );
export const GetPropertyAssetListDocument = `
    query GetPropertyAssetList($accountNumber: ID!) {
  getPropertyAssetList(accountNumber: $accountNumber) {
    accountNumber
    fileName
    fileUrl
    validationReportName
    validationReport
    templateURL
    countyContactsURL
  }
}
    `;
export const useGetPropertyAssetListQuery = <
      TData = GetPropertyAssetListQuery,
      TError = unknown
    >(
      variables: GetPropertyAssetListQueryVariables,
      options?: UseQueryOptions<GetPropertyAssetListQuery, TError, TData>
    ) =>
    useQuery<GetPropertyAssetListQuery, TError, TData>(
      ['GetPropertyAssetList', variables],
      useFetchData<GetPropertyAssetListQuery, GetPropertyAssetListQueryVariables>(GetPropertyAssetListDocument).bind(null, variables),
      options
    );
export const RemovePropertyAssetListDocument = `
    mutation RemovePropertyAssetList {
  removePropertyAssetList
}
    `;
export const useRemovePropertyAssetListMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<RemovePropertyAssetListMutation, TError, RemovePropertyAssetListMutationVariables, TContext>) =>
    useMutation<RemovePropertyAssetListMutation, TError, RemovePropertyAssetListMutationVariables, TContext>(
      ['RemovePropertyAssetList'],
      useFetchData<RemovePropertyAssetListMutation, RemovePropertyAssetListMutationVariables>(RemovePropertyAssetListDocument),
      options
    );
export const SavePropertyAssetListDocument = `
    mutation SavePropertyAssetList($accountNumber: ID!, $fileName: String!, $userId: ID!) {
  savePropertyAssetList(
    accountNumber: $accountNumber
    fileName: $fileName
    userId: $userId
  ) {
    accountNumber
    fileName
    validationReport
  }
}
    `;
export const useSavePropertyAssetListMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SavePropertyAssetListMutation, TError, SavePropertyAssetListMutationVariables, TContext>) =>
    useMutation<SavePropertyAssetListMutation, TError, SavePropertyAssetListMutationVariables, TContext>(
      ['SavePropertyAssetList'],
      useFetchData<SavePropertyAssetListMutation, SavePropertyAssetListMutationVariables>(SavePropertyAssetListDocument),
      options
    );
export const LoginDocument = `
    mutation Login($accountNumber: String!, $pin: String!) {
  login(accountNumber: $accountNumber, pin: $pin) {
    token
  }
}
    `;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['Login'],
      useFetchData<LoginMutation, LoginMutationVariables>(LoginDocument),
      options
    );
export const PinResetDocument = `
    mutation PinReset($pinResetRequestInput: PinResetRequestInput!) {
  pinReset(pinResetRequestInput: $pinResetRequestInput)
}
    `;
export const usePinResetMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<PinResetMutation, TError, PinResetMutationVariables, TContext>) =>
    useMutation<PinResetMutation, TError, PinResetMutationVariables, TContext>(
      ['PinReset'],
      useFetchData<PinResetMutation, PinResetMutationVariables>(PinResetDocument),
      options
    );
export const GetSummaryDocument = `
    query GetSummary {
  getSummary {
    accountNumber
    assetDeclared {
      units
      cost
      value
    }
    netTotal {
      cost
      value
    }
    leasedAssetListURL {
      pdf {
        filename
        fileUrl
      }
      csv {
        filename
        fileUrl
      }
    }
    propertyAssetListURL {
      pdf {
        filename
        fileUrl
      }
      csv {
        filename
        fileUrl
      }
    }
    countyContactsURL
    contacts {
      id
      name
      email
      phone
      contactType
    }
  }
}
    `;
export const useGetSummaryQuery = <
      TData = GetSummaryQuery,
      TError = unknown
    >(
      variables?: GetSummaryQueryVariables,
      options?: UseQueryOptions<GetSummaryQuery, TError, TData>
    ) =>
    useQuery<GetSummaryQuery, TError, TData>(
      variables === undefined ? ['GetSummary'] : ['GetSummary', variables],
      useFetchData<GetSummaryQuery, GetSummaryQueryVariables>(GetSummaryDocument).bind(null, variables),
      options
    );
export const SaveSummaryDocument = `
    mutation SaveSummary($summaryInput: SummaryInput!) {
  saveSummary(summaryInput: $summaryInput)
}
    `;
export const useSaveSummaryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveSummaryMutation, TError, SaveSummaryMutationVariables, TContext>) =>
    useMutation<SaveSummaryMutation, TError, SaveSummaryMutationVariables, TContext>(
      ['SaveSummary'],
      useFetchData<SaveSummaryMutation, SaveSummaryMutationVariables>(SaveSummaryDocument),
      options
    );
export const GetOathStatementDocument = `
    query GetOathStatement {
  getOathStatement {
    taxYear
    exemptionLimit
    filingDueDate
    interestRate
    penaltyAmount
    penaltyPercent
  }
}
    `;
export const useGetOathStatementQuery = <
      TData = GetOathStatementQuery,
      TError = unknown
    >(
      variables?: GetOathStatementQueryVariables,
      options?: UseQueryOptions<GetOathStatementQuery, TError, TData>
    ) =>
    useQuery<GetOathStatementQuery, TError, TData>(
      variables === undefined ? ['GetOathStatement'] : ['GetOathStatement', variables],
      useFetchData<GetOathStatementQuery, GetOathStatementQueryVariables>(GetOathStatementDocument).bind(null, variables),
      options
    );
export const SessionContextDocument = `
    query SessionContext {
  getSessionContext {
    accountNumber
    businessName
    taxYear
    stepsCompleted
    delinquentTaxYears
  }
}
    `;
export const useSessionContextQuery = <
      TData = SessionContextQuery,
      TError = unknown
    >(
      variables?: SessionContextQueryVariables,
      options?: UseQueryOptions<SessionContextQuery, TError, TData>
    ) =>
    useQuery<SessionContextQuery, TError, TData>(
      variables === undefined ? ['SessionContext'] : ['SessionContext', variables],
      useFetchData<SessionContextQuery, SessionContextQueryVariables>(SessionContextDocument).bind(null, variables),
      options
    );
export const GetLeaseAssetListDocument = `
    query GetLeaseAssetList($accountNumber: ID!) {
  getLeaseAssetList(accountNumber: $accountNumber) {
    accountNumber
    fileName
    fileUrl
    validationReportName
    validationReport
    templateURL
    countyContactsURL
  }
}
    `;
export const useGetLeaseAssetListQuery = <
      TData = GetLeaseAssetListQuery,
      TError = unknown
    >(
      variables: GetLeaseAssetListQueryVariables,
      options?: UseQueryOptions<GetLeaseAssetListQuery, TError, TData>
    ) =>
    useQuery<GetLeaseAssetListQuery, TError, TData>(
      ['GetLeaseAssetList', variables],
      useFetchData<GetLeaseAssetListQuery, GetLeaseAssetListQueryVariables>(GetLeaseAssetListDocument).bind(null, variables),
      options
    );
export const SaveLeaseAssetListDocument = `
    mutation SaveLeaseAssetList($accountNumber: ID!, $fileName: String!, $userId: ID!) {
  saveLeaseAssetList(
    accountNumber: $accountNumber
    fileName: $fileName
    userId: $userId
  ) {
    accountNumber
    fileName
    validationReport
  }
}
    `;
export const useSaveLeaseAssetListMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SaveLeaseAssetListMutation, TError, SaveLeaseAssetListMutationVariables, TContext>) =>
    useMutation<SaveLeaseAssetListMutation, TError, SaveLeaseAssetListMutationVariables, TContext>(
      ['SaveLeaseAssetList'],
      useFetchData<SaveLeaseAssetListMutation, SaveLeaseAssetListMutationVariables>(SaveLeaseAssetListDocument),
      options
    );
export const SkipLeaseAssetListDocument = `
    mutation SkipLeaseAssetList($accountNumber: ID!) {
  skipLeaseAssetList(accountNumber: $accountNumber)
}
    `;
export const useSkipLeaseAssetListMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<SkipLeaseAssetListMutation, TError, SkipLeaseAssetListMutationVariables, TContext>) =>
    useMutation<SkipLeaseAssetListMutation, TError, SkipLeaseAssetListMutationVariables, TContext>(
      ['SkipLeaseAssetList'],
      useFetchData<SkipLeaseAssetListMutation, SkipLeaseAssetListMutationVariables>(SkipLeaseAssetListDocument),
      options
    );
export const DismissLeaseAssetListDocument = `
    mutation DismissLeaseAssetList($accountNumber: ID!) {
  dismissLeaseAssetList(accountNumber: $accountNumber)
}
    `;
export const useDismissLeaseAssetListMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DismissLeaseAssetListMutation, TError, DismissLeaseAssetListMutationVariables, TContext>) =>
    useMutation<DismissLeaseAssetListMutation, TError, DismissLeaseAssetListMutationVariables, TContext>(
      ['DismissLeaseAssetList'],
      useFetchData<DismissLeaseAssetListMutation, DismissLeaseAssetListMutationVariables>(DismissLeaseAssetListDocument),
      options
    );
export const ThankYouDocument = `
    query ThankYou {
  getThankYou {
    taxYear
    accountName
    accountNumber
    filingDeadline
    interestRate
    filingDate
    filingTime
    leaseFile {
      pdf {
        filename
        fileUrl
      }
      csv {
        filename
        fileUrl
      }
    }
    nonLeaseFile {
      pdf {
        filename
        fileUrl
      }
      csv {
        filename
        fileUrl
      }
    }
    countyContactsURL
    penaltyPercent
  }
}
    `;
export const useThankYouQuery = <
      TData = ThankYouQuery,
      TError = unknown
    >(
      variables?: ThankYouQueryVariables,
      options?: UseQueryOptions<ThankYouQuery, TError, TData>
    ) =>
    useQuery<ThankYouQuery, TError, TData>(
      variables === undefined ? ['ThankYou'] : ['ThankYou', variables],
      useFetchData<ThankYouQuery, ThankYouQueryVariables>(ThankYouDocument).bind(null, variables),
      options
    );
export const GetExampleFileDocument = `
    query getExampleFile($isLeased: Boolean) {
  getExampleFile(isLeased: $isLeased) {
    fileName
  }
}
    `;
export const useGetExampleFileQuery = <
      TData = GetExampleFileQuery,
      TError = unknown
    >(
      variables?: GetExampleFileQueryVariables,
      options?: UseQueryOptions<GetExampleFileQuery, TError, TData>
    ) =>
    useQuery<GetExampleFileQuery, TError, TData>(
      variables === undefined ? ['getExampleFile'] : ['getExampleFile', variables],
      useFetchData<GetExampleFileQuery, GetExampleFileQueryVariables>(GetExampleFileDocument).bind(null, variables),
      options
    );