import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Loader from '../../components/Loader'
import useAuth from '../../hooks/useAuth'

import paths from '../../utils/paths'

export default function LoginRedirector(): JSX.Element {
  const { authInformation, user, signOut } = useAuth()
  const navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState(true)

  useEffect(() => {
    if (authInformation && user) {
      navigate(paths.index)
    } else {
      setLoggedIn(false)
    }
  }, [authInformation, user, signOut, navigate])

  if (!loggedIn) {
    return <Outlet />
  }

  return <Loader loading={true}/>
}
